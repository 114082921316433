@navbg: rgb(19,41,70);

.navbar {
	background: @navbg;
	font-family: 'Lato', sans-serif !important;
	border-radius: 0 !important;
	height: 50px;
	text-shadow: none;
	z-index: 10 !important;
	-webkit-font-smoothing:antialiased;
	a {
		color: rgba(255,255,255,0.7);
	}
}

@media(max-width:768px){
  .navbar {
  	margin-left:-1px;
  }
  .navbar li.active {
	border-bottom: 0;
	background:rgba(255,255,255,0.2);
  }
}

.navbar-toggle .icon-bar {
	background:rgba(255,255,255,0.6);
}

.navbar-toggle:hover .icon-bar, .navbar-toggle:focus {
	background: white;
}

.navbar-brand {
	img {
		margin-top: -7px;
	};
}

.navbar li.active {
	border-bottom: 2px solid rgba(255,255,255,0.7);
}


.navbar .nav > li {
	margin-right: 7px;
	margin-left: 7px;
}

.navbar .nav > li:not(.active) > a:hover, .nav > li:not(.active) > a:focus {
	background: none;
	border-bottom: 2px solid white;
	color: white;
}

.navbar .nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
	background: none !important;
	border-color: white !important;
}

/* uncollapsed on small screens*/
.navbar-collapse.in {
	background: @navbg;
	border: none;
	li.active {
		background: rgba(255,255,255,0.2);
		border: none;
	};
	a:hover {
		border-bottom: 0 !important;
		background: rgba(255,255,255,0.2);
	}
}
